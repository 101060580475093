body {
  background-color: #3a3a3a;
  color: #d0d0d0;
  font-family: 'Ubuntu', sans-serif;
}
/*@media screen and (prefers-color-scheme: light) {
  body {
    background-color: #d0d0d0;
    color: #3a3a3a;
  }
}*/
.header {
  padding: 60px;
  padding: 3.5vmin;
  text-align: center;
  background: #98BCBD;
  color: #3a3a3a;
  font-size: 40px;
  font-size: 3.5vmin;
}
.section {
  padding: 40px;
  padding: 3.5vh;
  text-align: left;
  font-size: 20px;
  font-size: 2vh;
}


/* unvisited link */
a:link {
  color: #98BCBD;
}

/* visited link */
a:visited {
  color: #719872;
}

/* mouse over link */
a:hover {
  color: #e19772;
}

/* selected link */
a:active {
  color:  #e17899;
}
